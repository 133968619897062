<template>
  <div>
    <container>

      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>

      <div slot="bottom_box_content">
        <component :is="curBottomComponent"></component>
      </div>

      <div slot="bg_map">
        <component :is="curMapComponent"></component>
      </div>

    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    IndexTable: () => import('./components/Bottom')
  },
  data () {
    return {
    }
  },
  created () {
    // if (!this.$store.hasModule('equipment')) {
    //   this.$store.registerModule('equipment', equipmentStoreModule)
    // }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setLeftComponent('')
    this.setRightComponent('')
    this.setBottomComponent('IndexTable')
  },
  destroyed () {
    // // 重置state，并注销moudle
    // this.$store.commit('reset_state')
    // this.$store.unregisterModule('equipment')
  }
}
</script>
